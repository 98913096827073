<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main flex">
			<div class="admin-aside admin-media">
				<AccountNav></AccountNav>
			</div>
			<div class="admin-col whole_000 whole_fff flex_bd">
				<div class="acc_box api_box">
					<div class="container">
						<div class="acc-hd flex flex-ac flex-zBetween">
							<div class="acc-tit">{{$t('meun10_6')}}</div>
						</div>
						<div class="accw-sel">
							<div class="acc-sel-flex acc_sel_time">
								<div class="acc-sel-t">{{$t('wtTxt')}}：</div>
								<el-date-picker v-model="daterange" type="daterange" range-separator="-"
									value-format="yyyy-MM-dd" :start-placeholder="$t('labStart')"
									:end-placeholder="$t('labEnd')">
								</el-date-picker>
							</div>
							<div class="acc-sel-flex">
								<div class="acc-sel-t">{{$t('czTxt2')}}：</div>
								<el-select v-model="symbol" :placeholder="$t('placeholder3')" clearable>
									<el-option v-for="(item,index) in options" :key="index" :label="item.unit"
										:value="item.unit">
									</el-option>
								</el-select>
							</div>
							<div class="acc-sel-flex">
								<div class="acc-sel-t">{{$t('wtTxt2')}}：</div>
								<el-select v-model="type" :placeholder="$t('placeholder3')" clearable>
									<el-option v-for="(item,index) in options2" :key="index" :label="$t(item.label)"
										:value="item.value">
									</el-option>
								</el-select>
							</div>
							<div class="acc-in-r acc-btn accw_btn">
								<el-button type="primary" class="btn" @click="search">{{$t('btn6')}}</el-button>
							</div>
						</div>
						<div class="acc-table exte-table accw-table">
							<el-table :data="tableData" style="width: 100%">
								<el-table-column prop="createTime" :label="$t('wtth1')">
									<template slot-scope="scope">
										<span>{{ scope.row.createTime | datefmt('YYYY-MM-DD HH:mm:ss') }}</span>
									</template>
								</el-table-column>
								<el-table-column prop="type" :label="$t('wtth2')">
									<template slot-scope="scope">
										<span v-for="item in options2" :key="item.value">
											<span v-if="scope.row.type == item.value">
												{{$t(item.label)}}
											</span>
										</span>
									</template>
								</el-table-column>
								<el-table-column prop="symbol" :label="$t('wtth3')">
								</el-table-column>
								<el-table-column prop="amount" :label="$t('wtth4')">
								</el-table-column>
								<el-table-column prop="fee" :label="$t('wtth5')">
								</el-table-column>
								<el-table-column prop="discountFee" :label="$t('wtth6')">
								</el-table-column>
								<el-table-column prop="realFee" :label="$t('wtth7')">
								</el-table-column>
								<el-table-column :label="$t('wtth8')">
									<template>
										<span>{{$t('orTab3')}}</span>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<div class="exte-box">
							<el-pagination layout="prev, pager, next" @current-change="handleCurrentChange"
								:current-page.sync="currentPage" :total="total" :background="true"></el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		transactionAll,
		coinInfo
	} from '@/api/api/user'
	import AccountNav from '@/components/AccountNav.vue'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			AccountNav,
			Foot
		},
		data() {
			return {
				daterange: '',
				options: [],
				options2: [{
						label: 'btn1',
						value: 0
					},
					{
						label: 'btn2',
						value: 1
					},
					{
						label: 'type1',
						value: 2
					},
					{
						label: 'type2',
						value: 3
					},
					{
						label: 'type3',
						value: 4
					},
					{
						label: 'type4',
						value: 5
					},
					{
						label: 'type5',
						value: 6
					},
					{
						label: 'type6',
						value: 7
					},
					{
						label: 'type7',
						value: 8
					},
					{
						label: 'type8',
						value: 9
					},
					{
						label: 'type9',
						value: 10
					},
					{
						label: 'type10',
						value: 11
					},
					{
						label: 'type11',
						value: 12
					},
					{
						label: 'type12',
						value: 13
					},
					{
						label: 'type13',
						value: 14
					},
					{
						label: 'type14',
						value: 15
					},
					{
						label: 'type15',
						value: 16
					},
					{
						label: 'type16',
						value: 17
					},
					{
						label: 'type17',
						value: 18
					},
					{
						label: 'type18',
						value: 19
					},
					{
						label: 'type19',
						value: 20
					},
					{
						label: 'type20',
						value: 21
					},
					{
						label: 'type21',
						value: 22
					},
					{
						label: 'type22',
						value: 23
					},
					{
						label: 'type23',
						value: 24
					},
					{
						label: 'type24',
						value: 25
					},
					{
						label: 'type25',
						value: 26
					},
					{
						label: 'type26',
						value: 27
					},
					{
						label: 'type27',
						value: 28
					},
					{
						label: 'type28',
						value: 29
					},
					{
						label: 'type29',
						value: 30
					},
					{
						label: 'type30',
						value: 31
					},
					{
						label: 'type31',
						value: 32
					},
					{
						label: 'type32',
						value: 33
					},
					{
						label: 'type33',
						value: 34
					},
					{
						label: 'type34',
						value: 35
					},
					{
						label: 'type35',
						value: 36
					},
					{
						label: 'type36',
						value: 37
					},
					{
						label: 'type37',
						value: 38
					},
					{
						label: 'type38',
						value: 39
					},
					{
						label: 'type39',
						value: 40
					},
					{
						label: 'type40',
						value: 41
					},
					{
						label: 'type41',
						value: 42
					},
					{
						label: 'type42',
						value: 43
					},
					{
						label: 'type43',
						value: 44
					}
				],
				symbol: '',
				type: '',
				tableData: [],
				currentPage: 1,
				pageSize: 10,
				total: 10
			};
		},
		mounted() {
			this.getList()
			this.getInfo()
		},
		methods: {
			getList() {
				transactionAll({
					endTime: this.daterange[1],
					pageNo: this.currentPage,
					pageSize: this.pageSize,
					startTime: this.daterange[0],
					symbol: this.symbol,
					type: this.type
				}).then(res => {
					if (res.code == 0) {
						this.tableData = res.data.content
						this.total = res.data.totalElements
						this.pageSize = res.data.size
					}
				})
			},
			getInfo() {
				coinInfo().then(res => {
					if (res.code == 0) {
						this.options = res.data
					} else {
						this.$message.error(res.message);
					}
				})
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.getList()
			},
			search() {
				if (this.daterange == []) {
					this.$message.error(this.$t('daterange'));
				} else {
					this.getList()
				}

			}
		}
	}
</script>
